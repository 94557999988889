<template>
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 20"
  >
    <path
      d="M6.167 1.667v2.5M12.833 1.667v2.5M2.417 7.575h14.167M17 7.083v7.084c0 2.5-1.25 4.166-4.167 4.166H6.167C3.25 18.333 2 16.667 2 14.167V7.083c0-2.5 1.25-4.166 4.167-4.166h6.666C15.75 2.917 17 4.583 17 7.083Z"
      stroke="#133949"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.58 11.417h.007M12.58 13.917h.007M9.496 11.417h.008M9.496 13.917h.008M6.412 11.417h.008M6.412 13.917h.008"
      stroke="#133949"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
